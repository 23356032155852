<template>
  <div class="body">
    <h1 class="text-secondary-1 fw-bold text-center mb-1">Forgot password?</h1>
    <p class="text-primary-0 text-center fw-bold">Take it easy. We help you.</p>
    <form class="d-flex flex-column gap-2 mt-4" @submit.prevent="resetPassword">
      <div class="field">
        <input-text
          id="email"
          type="email"
          placeholder="Email"
          class="shadow-sm mb-3 text-primary-1 rounded-0"
          v-model="emailValue"
          required
        />
      </div>
      <button
        type="submit"
        class="
          btn
          bl-shadow
          text-white
          bg-primary-1 bg-hover-primary-1
          p-2
          px-5
          w-100
          mt-3
        "
        :disabled="isResetPasswordLoading"
      >
        Send reset link
      </button>
      <div class="d-flex gap-1 align-items-center justify-content-center">
        <p>Did you remember it?</p>
        <router-link :to="{ name: 'login' }">
          <button
            type="button"
            class="btn text-secondary-1 text-hover-secondary-1 p-2 px-2"
          >
            Login
          </button>
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import useResetPassword from "@/modules/public/composables/useResetPassword";

export default {
  components: {},
  setup() {
    const { emailValue, resetPassword, isResetPasswordLoading } =
      useResetPassword();
    return {
      emailValue,
      resetPassword,
      isResetPasswordLoading,
    };
  },
};
</script>
