import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import hasAStringALowercase from "@/shared/utils/validations/hasAStringALowercase";
import hasAStringAUppercase from "@/shared/utils/validations/hasAStringAUppercase";
import isEqual from "@/shared/utils/validations/isEqual";

const useResetPassword = () => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();

  const isValidateToken = ref(true);
  const isTokenValidateLoading = ref(true);
  const currentScreen = ref("");

  onBeforeMount(() => (currentScreen.value = route.name));

  onMounted(async () => {
    currentScreen.value = "";
    if (route.name === "create-password") {
      const token = route.params.token;
      if (!token) {
        router.push({ name: "login" });
        return;
      }

      const { success } = await store.dispatch("auth/tokenValidate", token);
      isValidateToken.value = success;
      isTokenValidateLoading.value = false;
      setTimeout(() => {}, 1200);
      return;
    }
    isTokenValidateLoading.value = true;
  });

  onBeforeUnmount(() => (currentScreen.value = ""));

  const fields = ref({ password: "", password_confirmation: "" });
  let requirements = ref([
    {
      text: "Must contain at least 10 characters",
      isValidate: false,
    },
    {
      text: "Must contain at least 1 lowercase",
      isValidate: false,
    },
    {
      text: "Must contain at least 1 capital letter",
      isValidate: false,
    },
    {
      text: "The passwords must be equal",
      isValidate: false,
    },
  ]);

  const requirementsValidated = computed(
    () => requirements.value.filter((req) => req.isValidate).length
  );

  watchEffect(() => {
    const { password, password_confirmation } = fields.value;
    if (password.length >= 10) requirements.value[0].isValidate = true;
    else requirements.value[0].isValidate = false;

    requirements.value[1].isValidate = hasAStringALowercase(password);
    requirements.value[2].isValidate = hasAStringAUppercase(password);
    requirements.value[3].isValidate = isEqual(password, password_confirmation);
  });

  const isCreatePassLoading = ref(false);
  const createPassword = async () => {
    const token = route.params.token;

    isCreatePassLoading.value = true;
    const { success } = await store.dispatch("auth/resetPassword", {
      token,
      ...fields.value,
    });
    isCreatePassLoading.value = false;

    if (success) {
      setTimeout(() => {
        requirements.value.map((r) => (r.isValidate = false));
        for (let [key, _] of Object.entries(fields.value)) {
          fields.value[key] = "";
        }
      }, 2000);
    }
  };

  const emailValue = ref("");
  const isResetPasswordLoading = ref(false);

  const resetPassword = async () => {
    isResetPasswordLoading.value = true;
    const { success } = await store.dispatch(
      "auth/forgotPassword",
      emailValue.value
    );
    if (!success) {
      emailValue.value = "";
    } else {
      setTimeout(() => {
        emailValue.value = "";
      }, 2000);
    }

    isResetPasswordLoading.value = false;
  };

  return {
    currentScreen,
    isValidateToken,
    isTokenValidateLoading,
    fields,
    requirements,
    requirementsValidated,
    isResetPasswordLoading,
    resetPassword,
    emailValue,
    isCreatePassLoading,
    createPassword,
  };
};

export default useResetPassword;
